import React from "react";
import { Layout, Row, Result } from 'antd';


const { Content } = Layout;

export const NotFound = () => {

    return (
        <Layout>
            <Content>
                <Row align="middle" justify="center" style={{height: '100vh'}}>
                    <Result
                        status="404"
                        title="404"
                        subTitle="Siden blev ikke fundet."
                    />
                </Row>
            </Content>
        </Layout>
    )
}