import React from "react";
import { Layout, Result } from 'antd';
import { SmileOutlined } from '@ant-design/icons';
import {
    TopNav,
    PoweredBy,
} from "~/components";


const { Header, Footer, Content } = Layout;

export const Dashboard = () => {

    return (
        <Layout>
            <Header>
                <TopNav />
            </Header>
            <Content style={{padding: '0 50px'}}>
                <Result
                    icon={<SmileOutlined />}
                    title="Yay!"
                />
            </Content>
            <Footer>
                <PoweredBy />
            </Footer>
        </Layout>
    )
}