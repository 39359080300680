import * as admin from "firebase-admin";
import { functions } from '~/services/firebase';


export interface SendPushNotificationParams {
    tokens: string[]
    title: string
    message: string
}

export interface SendPushNotificationResult {
    success: boolean
    response?: admin.messaging.BatchResponse
}

export const sendPushNotification = functions.httpsCallable('sendPushNotification')
