import React, { FC, useState } from "react";
import { Modal, Form, Input, Button, message } from 'antd';
import { sendPushNotification } from '~/services/messaging';


interface SendPushModalProps {
    visible: boolean
    setVisible: (visible: boolean) => void
    tokens: string[]
}

/**
 * This displays a Modal with send push form
 */
export const SendPushModal: FC<SendPushModalProps> = ({ visible, setVisible, tokens }) => {

    const [sending, setSending] = useState(false)
    const [sendForm] = Form.useForm()

    const sendMessage = () => {
        sendForm.validateFields()
        .then(values => {
            setSending(true)
            sendPushNotification({tokens: tokens, title: values.title, message: values.message}).then((result) => {
                setVisible(false)
                message.success('Pushbesked sendt!')
            }).catch(function(err) {
                console.log(err)
                setVisible(false)
                message.error('Kunne ikke sende pushbesked')
            })
        })
    }

    return (
        <Modal
            title="Send pushbesked"
            visible={visible}
            maskClosable={!sending}
            closable={!sending}
            afterClose={() => {
                sendForm.resetFields()
                setSending(false)
            }}
            footer={[
                <Button key="cancel" disabled={sending} onClick={() => setVisible(false)}>
                    Annuller
                </Button>,
                <Button
                    key="send"
                    type="primary"
                    loading={sending}
                    onClick={sendMessage}>
                    Send
                </Button>
            ]}>
            <Form
                form={sendForm}
                layout="vertical">
                <Form.Item
                    name="title"
                    label="Titel"
                    rules={[{ required: true, message: 'Skal udfyldes!' }]}>
                    <Input disabled={sending} />
                </Form.Item>
                <Form.Item
                    name="message"
                    label="Besked"
                    rules={[{ required: true, message: 'Skal udfyldes!' }]}>
                    <Input.TextArea disabled={sending} />
                </Form.Item>
            </Form>
        </Modal>
    )
}
