import React from "react";
import { Layout, Row, Result, Button } from 'antd';
import { useHistory } from "react-router-dom";
import { signOut } from "~/services/auth";


const { Content } = Layout;

export const AccessDenied = () => {

    const history = useHistory()

    const handleSignOut = () => {
        signOut()
        history.push("/signin")
    }

    return (
        <Layout>
            <Content>
                <Row align="middle" justify="center" style={{height: '100vh'}}>
                    <Result
                        status="error"
                        title="Adgang nægtet"
                        subTitle="Du har ikke Administrator rettigheder!"
                        extra={[
                            <Button
                                type="primary"
                                onClick={() => handleSignOut()}>
                                Log ud
                            </Button>
                        ]}
                    />
                </Row>
            </Content>
        </Layout>
    )
}